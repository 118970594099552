import { useEffect, useRef, useState, useCallback } from "react";
import OperatorGuideService from "../services/OperatorGuideService";
import { AssignedIncidentResponse } from "../model/incident/AssignedIncidentResponse";

export default function useRequestExecution() {
  const currentAssignedIncidentRef = useRef<AssignedIncidentResponse>(null);
  const [currentAssignedIncident, setCurrentAssignedIncident] =
    useState<AssignedIncidentResponse | null>(null);
  const [isRequestExecutionInUse, setIsRequestExecutionInUse] = useState(false);
  const requestExecutionTimeout = useRef<NodeJS.Timeout>();

  const requestExecution = useCallback(() => {
    setCurrentAssignedIncident(null);
    setIsRequestExecutionInUse(true);
    OperatorGuideService.getAssignedExecution()
      .then((response: AssignedIncidentResponse) => {
        if (response) {
          currentAssignedIncidentRef.current = response;
          setCurrentAssignedIncident(response);
        } else if (isDMS) {
          requestExecutionTimeout.current = setTimeout(requestExecution, 2000);
        }
      })
      .catch(() => {
        if (isDMS) {
          requestExecutionTimeout.current = setTimeout(requestExecution, 2000);
        }
      })
      .finally(() => setIsRequestExecutionInUse(false));
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      requestExecution();
    }
    return () => {
      clearTimeout(requestExecutionTimeout.current);
      isMounted = false;
    };
  }, [requestExecution]);

  return {
    requestExecution,
    currentAssignedIncident,
    isRequestExecutionInUse,
    currentAssignedIncidentRef,
  };
}
