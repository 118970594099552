import React from "react";
import ReactDOM from "react-dom";
import { getDateAndTimeFromTimestamp, MediaInfo } from "compass-commons";
import MediaModal, {
  IMAGE_TYPES,
} from "../../../commons/mediaModal/MediaModal";
import "./mediaPortal.moduel.css";
import { getDocument } from "../../../../utils/Utils";

interface MediaPortalProps {
  media: MediaInfo;
  portalWrapperId: string;
  onClose: (Event) => void;
  show: boolean;
  modalId: string;
  mediaFile: any;
  elementsShadowBack?: string[] /** Element Ids if we want to hide back */;
}

/**
 * Media Portal. This portal is used to show media content
 * This component uses common Media Modal.
 * @param props
 * @constructor
 */
const MediaPortal = (props: MediaPortalProps) => {
  const {
    media,
    portalWrapperId,
    show,
    onClose,
    modalId,
    mediaFile,
    elementsShadowBack,
  } = props;

  elementsShadowBack?.forEach((elm) => {
    const el = getDocument(document).getElementById(elm);
    if (el) el.style.zIndex = "-1";
  });

  const style: React.CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    height: "60%",
    top: "15%",
    width: "45%",
  };
  const playerStyle: React.CSSProperties = {
    width: IMAGE_TYPES.indexOf(media?.extension) >= 0 ? "80%" : "100%",
    display: "block",
    margin: "auto",
  };

  const onCloseHandler = (event) => {
    elementsShadowBack?.forEach((elm) => {
      const el = getDocument(document).getElementById(elm);
      if (el) el.style.zIndex = "1";
    });

    onClose(event);
  };

  return ReactDOM.createPortal(
    <div className="modal-dialog-wrapper">
      <MediaModal
        mediaType={media?.extension}
        mediaTitle={media?.id}
        mediaInfo={getDateAndTimeFromTimestamp("")}
        id={media?.id}
        onClose={onCloseHandler}
        show={show}
        activeModalId={modalId}
        mediaFile={mediaFile}
        modalStyle={style}
        playerStyle={playerStyle}
      />
    </div>,
    getDocument(document).getElementById(portalWrapperId)
  );
};

export default MediaPortal;
