// General
import React from "react";
import OperatorGuideActivationsHeader from "./OperatorGuideActivationsHeader";

interface OperatorGuideExecutionHeaderProps {
  additionalActivationsHeader: {
    isChecked: boolean;
    count: number;
  };
}
const OperatorGuideExecutionHeader = ({
  additionalActivationsHeader,
}: OperatorGuideExecutionHeaderProps): JSX.Element => {
  return (
    <div className="operator-guide-execution-header">
      <OperatorGuideActivationsHeader
        count={additionalActivationsHeader.count}
        isChecked={additionalActivationsHeader.isChecked}
      />
    </div>
  );
};

export default OperatorGuideExecutionHeader;
