import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "@mui/material";
import { ImageRounded } from "@mui/icons-material";
import {
  ImageLoadState,
  SearchImageBase,
} from "../../../../../../../model/databaseSearch/internal/SearchImageBase";
import SubsystemManagerService from "../../../../../../../services/SubsystemManagerService";
import ImageDTO from "../../../../../../../model/databaseSearch/ImageDTO";

interface SingleImageProps {
  image: SearchImageBase;
  displayingSelectedOption: boolean;
  subsystemId: string;
  optionId: string;
  onImageStateChange?: (
    image: SearchImageBase,
    optionId: string,
    imageId: string
  ) => void;
}

function SingleImage(props: SingleImageProps): React.JSX.Element {
  const {
    image,
    displayingSelectedOption,
    subsystemId,
    optionId,
    onImageStateChange,
  } = props;
  const [searchImage, setSearchImage] = useState<SearchImageBase>(image);
  const targetElement = useRef(null);

  // Callback function when the image is in the viewport
  const onIntersection = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];

    // If there is not intersection or the image is already loading, loaded or has given an error, do nothing
    if (
      !entry.isIntersecting ||
      searchImage.state !== ImageLoadState.LOAD_NOT_STARTED
    ) {
      return;
    }

    setSearchImage((prevSearchImage) => ({
      ...prevSearchImage,
      state: ImageLoadState.LOADING,
    }));

    SubsystemManagerService.getImage(subsystemId, searchImage.imageId)
      .then((res: ImageDTO) => {
        if (res) {
          setSearchImage({
            ...searchImage,
            imageData: res.rawData,
            fileType: res.fileType,
            state: ImageLoadState.LOADED,
          });
        }
      })
      .catch(() => {
        setSearchImage((prevSearchImage) => ({
          ...prevSearchImage,
          imageData: null,
          state: ImageLoadState.ERROR_LOADING,
        }));
      });
  };

  // As searchImage gets updated, we want to use the callback
  useEffect(() => {
    if (onImageStateChange != null) {
      onImageStateChange(searchImage, optionId, image.imageId);
    }
  }, [searchImage]);

  useEffect(() => {
    // We do not want to fetch images but we want to show
    if (!displayingSelectedOption) {
      return () => {};
    }

    // Create an observer
    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.1,
    });

    if (targetElement.current) {
      observer.observe(targetElement.current);
    }

    // Remove the observer when the component is unmounted
    return () => {
      if (targetElement.current) {
        observer.unobserve(targetElement.current);
      }
    };
  }, []);

  return searchImage.state !== ImageLoadState.LOADED ? (
    <Avatar
      ref={targetElement}
      data-cr="avatar-image-not-loaded"
      key={searchImage.imageId}
      className="og-search-task-dropdown-avatar"
    >
      <ImageRounded className="og-search-task-dropdown-option-avatar-icon" />
    </Avatar>
  ) : (
    // Display fetched image
    <Avatar
      key={searchImage.imageId}
      data-cr="avatar-image-loaded"
      className="og-search-task-dropdown-avatar avatar-border"
      src={`data:image/${searchImage.fileType};base64,${searchImage.imageData}`}
    />
  );
}

SingleImage.defaultProps = {
  onImageStateChange: null,
};

// eslint-disable-next-line import/prefer-default-export
export { SingleImage };
