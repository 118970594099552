import DatabaseSearchResult from "../../../../../../model/databaseSearch/internal/DatabaseSearchResult";
import { OptionType } from "../../../../../../model/databaseSearch/internal/OptionType";

export default function CreateNotFoundOption(
  notFoundMessage?: string
): DatabaseSearchResult {
  return {
    id: "_",
    subsystemId: "_",
    subsystemName: "_",
    infoFields: [],
    type: OptionType.NOT_FOUND,
    textToDisplay: notFoundMessage,
    images: [],
  };
}
