import React, { useState } from "react";
import { TextField, RadioButton, RadioGroup, Dialog, Button } from "dms-lib";
import { useI18n } from "compass-commons";
import { COMMENTS_MAXIMUM_CHARACTERS } from "../../../../utils/constants";
import "./classifyIncident.module.css";

export interface ClassificationResult {
  selected: string;
  comment: string;
}

interface ClassifyIncidentProps {
  openDialog: boolean;
  // eslint-disable-next-line no-shadow
  selectionCallback: (ClassificationResult) => void;
  isOGCompleted?: boolean;
  showNotImplementedFeatures?: boolean;
}

const ClassifyIncident = (props: ClassifyIncidentProps): JSX.Element => {
  const {
    openDialog,
    selectionCallback,
    isOGCompleted,
    showNotImplementedFeatures,
  } = props;
  const [isOpen, setIsOpen] = useState(openDialog);

  const { t: translate } = useI18n();

  const [classification, setClassification] = useState({
    selected: null,
    comment: null,
  } as ClassificationResult);

  /** Static texts block */
  const OPTION_DRILL_TEXT = "Drill";
  const OPTION_FALSE_ALARM_TEXT = "False alarm";
  const OPTION_FALSE_ALARM_STAFF_TEXT = "False alarm | Staff";
  const OPTION_FALSE_ALARM_TECHNICAL_TEXT = "False alarm | Technical";
  const OPTION_REAL_ALARM_TEXT = "Real alarm";
  const OPTION_TEST_TEXT = "Test";
  const CLASSIFY_INCIDENT_TITLE_TEXT =
    "Before you go, please classify this incident:";
  /** Static text block ends */

  const handleChangeRadioGroup = (value: string) => {
    setClassification({
      ...classification,
      selected: value,
    });
  };

  const dialogCallback = (isConfirmed: boolean) => {
    if (isConfirmed) {
      selectionCallback(classification);
    }
    selectionCallback(null);
  };

  const getOptions = () => {
    const optionsArr = [
      OPTION_DRILL_TEXT,
      OPTION_FALSE_ALARM_TEXT,
      OPTION_FALSE_ALARM_STAFF_TEXT,
      OPTION_FALSE_ALARM_TECHNICAL_TEXT,
      OPTION_REAL_ALARM_TEXT,
      OPTION_TEST_TEXT,
    ];

    const options = [];

    optionsArr.forEach((op: string, index) =>
      options.push(
        <RadioButton
          key={index}
          value={op}
          style={{ fontWeight: "bold" }}
          label={op}
        />
      )
    );
    return options;
  };

  const onChangeHandler = (event) => {
    const comment =
      event.target.value?.trim().length > 0
        ? event.target.value.substring(0, COMMENTS_MAXIMUM_CHARACTERS - 1)
        : null;
    setClassification((prev) => {
      return {
        ...prev,
        comment,
      };
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    dialogCallback(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    dialogCallback(true);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      size="sm"
      dialogTitle={
        !isOGCompleted && (
          <span className="dms-classify-dialog-title">
            {translate("incident.operatorGuideNotCompeted")}
          </span>
        )
      }
      dialogContent={
        <div className="classification-content">
          {!isOGCompleted && (
            <div className="unfinished-og" data-cr="classify-incident">
              <div>
                <span>
                  {translate("incident.operatorGuideNotCompetedReasonQuestion")}
                </span>
              </div>
              <TextField
                value={classification.comment}
                placeholder={translate("incident.commentInputPlaceholder")}
                onChange={onChangeHandler}
                autoFocus
              />
            </div>
          )}
          {showNotImplementedFeatures && (
            <>
              <div style={{ marginTop: "10px" }}>
                <span>{CLASSIFY_INCIDENT_TITLE_TEXT}</span>
              </div>
              <div className="classification">
                <RadioGroup
                  value={classification.selected}
                  onChange={(value) => handleChangeRadioGroup(value)}
                >
                  {...getOptions()}
                </RadioGroup>
              </div>
            </>
          )}
        </div>
      }
      dialogActions={
        <>
          <Button
            dataCr="disapproval-button"
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            {translate("cancel", { ns: "Shared" })}
          </Button>
          <Button
            dataCr="confirmation-button"
            color="primary"
            variant="contained"
            className="form-dialog-confirm"
            disabled={!classification?.comment}
            onClick={handleConfirm}
          >
            {translate("incident.clearBtn")}
          </Button>
        </>
      }
    />
  );
};
ClassifyIncident.defaultProps = {
  isOGCompleted: false,
  showNotImplementedFeatures: false,
};
export default ClassifyIncident;
