import React, { useCallback, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { lazy } from "@loadable/component";
import { SizeMe } from "react-sizeme";

import "./widgetZone.module.css";

import {
  createDateFromString,
  getNewId,
  useGetToken,
  useI18n,
  useStatefulArray,
} from "compass-commons";
import { ErrorData } from "compass-widget-library";
import { CircularProgress } from "@mui/material";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { Widget } from "../../model/widget/Widget";
import { ResourceFunctionalityType } from "../../model/resource/ResourceFunctionality";
import { ResourceMappingLightDTO } from "../../model/incident/ResourceMappingLightDTO";
import { addResourceCameraWidget } from "../../helpers/openWidgets";
import ErrorBoundary from "../../errors/ErrorBoundary";
import ErrorPage from "../../errors/ErrorPage";
import { getErrorLabelCameraWidget } from "../../helpers/errorMapperHelper";
import WidgetType from "../../model/widget/WidgetType";

const CameraWidgetComponent = lazy(() =>
  import("compass-widget-library").then((module) => module.CameraWidget)
);

const WidgetWrapperComponent = lazy(() =>
  import("compass-widget-library").then((module) => module.WidgetWrapper)
);

interface WidgetZoneProps {
  defaultWidgets?: Widget[];
  camerasToOpen?: ResourceMappingLightDTO[];
}

const { SERVER_URL, RESOURCE_MAPPING_MANAGER_PATH, MAX_NUM_WIDGETS } =
  appConfig;

const WidgetZone = (props: WidgetZoneProps) => {
  const { t: translate } = useI18n();
  const { defaultWidgets, camerasToOpen } = props;
  const widgetZoneRef = React.useRef(null);
  const { stateService } = useGlobalContext();
  const { clearIncidentAlert, currentWidgets, alertSubject } = stateService;
  const {
    array: widgets,
    set: setWidgets,
    add: addWidget,
    remove: removeWidget,
    contains: containsWidget,
    clear: clearWidgets,
  } = useStatefulArray<Widget>(
    defaultWidgets?.length > 0 ? defaultWidgets : currentWidgets.value
  );
  const [widgetZoneHeight, setWidgetZoneHeight] = useState(0);
  const [widgetZoneWidth, setWidgetZoneWidth] = useState(0);
  const [connectedDevices, setConnectedDevices] = useState([]);
  const DEFAULT_MARGIN = 9;
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  useEffect(() => {
    const clearIncidentAlertSubscribe = clearIncidentAlert.subscribe((val) => {
      if (val !== null) clearWidgets();
    });

    return function cleanup() {
      clearIncidentAlertSubscribe.unsubscribe();
    };
  }, [clearIncidentAlert]);

  const handleNewWidgetEvent = useCallback(
    (ev: CustomEvent) => {
      ev.stopPropagation();
      const resource = ev?.detail?.resource;
      if (resource) {
        const newWidget = {
          id: resource.resourceMappingId,
          width: 0,
          height: 0,
          isVisible: true,
          owner: ev?.detail?.floorPlan,
          resourceMapping: {
            resourceType: resource.resourceType,
            resourceId: resource.resourceId,
            resourceMappingId: resource.resourceMappingId,
            name: resource.resourceName,
          },
          type: ev?.detail?.widgetType,
          videoStartAt: ev?.detail?.videoStartAt,
        } as Widget;

        if (WidgetType.RECORDED_VIDEO === newWidget.type) {
          newWidget.transientId = getNewId();
        }

        if (
          !containsWidget(
            newWidget,
            (a: Widget, b: Widget) =>
              a?.id === b?.id &&
              WidgetType.LIVE_CAMERA === a?.type &&
              WidgetType.LIVE_CAMERA === b?.type
          )
        ) {
          if (widgets.length >= parseInt(MAX_NUM_WIDGETS)) {
            setAlertMessage(translate("widgetZone.widgetLimitExceeded"));
            setAlertIsOpen(true);
            setTimeout(() => {
              setAlertIsOpen(false);
            }, 4000);
          } else {
            addWidget(newWidget);
          }
        } else {
          setAlertMessage(translate("widgetZone.duplicate"));
          setAlertIsOpen(true);
          setTimeout(() => {
            setAlertIsOpen(false);
          }, 4000);
        }
      }
    },
    [widgets]
  );

  useEffect(() => {
    document.addEventListener("addNewWidgetEvent", handleNewWidgetEvent);

    return () => {
      document.removeEventListener("addNewWidgetEvent", handleNewWidgetEvent);
    };
  }, [widgets]);

  const openNearestCameras = (
    videoResourceMappings: ResourceMappingLightDTO[]
  ) => {
    if (videoResourceMappings?.length > 0) {
      Object.values(videoResourceMappings).forEach((videoSource) =>
        addResourceCameraWidget(videoSource, null, WidgetType.LIVE_CAMERA)
      );
    }
  };

  useEffect(() => {
    openNearestCameras(camerasToOpen);
    return () => clearWidgets();
  }, [camerasToOpen]);

  useEffect(() => {
    setWidgetZoneHeight(widgetZoneRef?.current?.clientHeight);
    setWidgetZoneWidth(widgetZoneRef?.current?.clientWidth);
    currentWidgets?.next(widgets);
  }, [widgets]);

  const handleResizeWindow = () => {
    setWidgetZoneHeight(widgetZoneRef?.current?.clientHeight);
    setWidgetZoneWidth(widgetZoneRef?.current?.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);

    return function cleanup() {
      currentWidgets?.next([]);
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const scroll = (
    widgetId: string,
    widgetType: WidgetType,
    transientId: string
  ) => {
    let elementId = `#react-grid-item-${widgetId}-${widgetType}`;
    if (transientId) {
      elementId = `${elementId}_${transientId}`;
    }
    const section = document.querySelector(elementId);
    section.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const getRecordedVideoWidget = (
    event: React.MouseEvent,
    widget: Widget
  ): Widget => {
    if (WidgetType.RECORDED_VIDEO !== widget.type) {
      return null;
    }

    const recordedVideoElement = event?.currentTarget?.closest(
      `#widget-grid-item-${widget.id}-${widget.type}_${widget.transientId}`
    );
    if (recordedVideoElement) {
      const recordedVideoWidget = widgets.find(
        (w) =>
          w.id === widget.id &&
          widget.type === w.type &&
          widget.transientId === w.transientId
      );
      if (recordedVideoWidget) {
        return recordedVideoWidget;
      }
    }
    return null;
  };

  const widgetResizeHandler = (event: React.MouseEvent, widget: Widget) => {
    const tempArr = [...widgets];
    const recordedVideoWidget = getRecordedVideoWidget(event, widget);
    tempArr.forEach((w) => {
      if (w.id === widget.id && w.type === widget.type) {
        if (
          recordedVideoWidget &&
          w.transientId !== recordedVideoWidget.transientId
        ) {
          return;
        }
        // eslint-disable-next-line no-param-reassign
        w.isMaximized = !w.isMaximized;
        let resizeElemId = `react-grid-item-${w.id}-${w.type}`;
        if (
          recordedVideoWidget &&
          w.transientId === recordedVideoWidget.transientId
        ) {
          resizeElemId = `${resizeElemId}_${recordedVideoWidget.transientId}`;
        }
        if (
          document.getElementById(resizeElemId).classList.contains("maximized")
        ) {
          document.getElementById(resizeElemId).classList.remove("maximized");
        } else {
          document.getElementById(resizeElemId).classList.add("maximized");
        }
      }
    });
    setWidgets([...tempArr]);

    const timeout = setTimeout(() => {
      scroll(widget.id, widget.type, recordedVideoWidget?.transientId);
    }, 100);
    return () => clearTimeout(timeout);
  };

  const handleDelete = (event: React.MouseEvent, widget: Widget) => {
    const widgetToRemove = getRecordedVideoWidget(event, widget) || widget;

    removeWidget(widgetToRemove);
  };

  const calculateWidgetSizes = () => {
    const width = widgetZoneWidth - DEFAULT_MARGIN * 4;
    const height = widgetZoneHeight - DEFAULT_MARGIN * 4;

    if (widgets?.length === 1) {
      return {
        minW: width,
        minH: height,
      };
    }
    const DEFAULT_ASPECT_RATIO = 16.0 / 9.0;
    let maxW = 0;
    let maxH = 0;
    let rowsMax = 0;
    let colsMax = 0;
    const minWidth = 200;
    for (let rows = 1, total = widgets.length; rows <= total; rows++) {
      for (let cols = 1; cols + rows <= total + 1; cols++) {
        if (cols * rows >= total) {
          let auxW = Math.floor(width / cols) - DEFAULT_MARGIN * 2;
          let auxH = Math.floor(auxW / DEFAULT_ASPECT_RATIO);

          if ((auxH + 2 * DEFAULT_MARGIN) * rows > height) {
            auxH = Math.floor(height / rows) - DEFAULT_MARGIN * 2;
            auxW = Math.floor(auxH * DEFAULT_ASPECT_RATIO);
          }

          if (maxH * maxW < auxH * auxW) {
            maxH = auxH;
            maxW = auxW;

            rowsMax = rows;
            colsMax = cols;
          } else if (
            maxH * maxW === auxH * auxW &&
            rowsMax * colsMax > rows * cols
          ) {
            rowsMax = rows;
            colsMax = cols;
          }
        }
      }
    }

    if (maxW < minWidth) {
      maxW = minWidth;
      maxH = Math.floor(minWidth / DEFAULT_ASPECT_RATIO);
    }

    return { minW: maxW, minH: maxH };
  };

  useEffect(() => {
    if (connectedDevices) {
      const updatedWidgets = [];
      connectedDevices.forEach((device) => {
        const wDev = widgets.find(
          (w) =>
            w.resourceMapping.resourceMappingId === device.resourceMappingId
        );
        if (wDev) {
          wDev.resourceMapping.status = device.connectionStatus.state;
          updatedWidgets.push(wDev);
        }
      });
      if (updatedWidgets.length > 0) {
        const tempArr = [...widgets];
        const widgetArr = tempArr?.slice();
        updatedWidgets.forEach((u) => {
          const index = widgetArr?.indexOf(
            widgetArr?.find((widget: Widget) => widget.id === u.id)
          );

          tempArr.splice(index, 1);
        });

        setWidgets([...tempArr, ...updatedWidgets]);
      }
    }
  }, [connectedDevices]);

  const connectionEstablishedCallback = useCallback(
    (id: string, cameraStatus: any) => {
      if (id && cameraStatus) {
        const newDevice = {
          resourceId: id,
          connectionStatus: cameraStatus,
        };
        setConnectedDevices([...connectedDevices, newDevice]);
      }
    },
    []
  );

  const token = useGetToken();
  const getWrapper = (widget: Widget) => {
    const { minW, minH } = calculateWidgetSizes();
    const widgetWrapperProps = {
      title: widget.resourceMapping?.name,
      deleteCallback: (event) => {
        handleDelete(event, widget);
      },
      isDraggable: false,
      maximizeCallback: (event) => widgetResizeHandler(event, widget),
      isMaximized: widget.isMaximized,
      dimensions: {
        width: widget.isMaximized
          ? `${widgetZoneWidth - DEFAULT_MARGIN * 2}px`
          : `${minW}px`,
        height: widget.isMaximized
          ? `${widgetZoneHeight - DEFAULT_MARGIN * 2}px`
          : `${minH}px`,
      },
    };

    if (
      widget.resourceMapping &&
      ResourceFunctionalityType.LIVE_VIDEO_STREAMING ===
        widget.resourceMapping?.resourceType
    ) {
      let videoStartAt = {};
      if (widget.videoStartAt) {
        videoStartAt = {
          videoStartAt: createDateFromString(widget.videoStartAt).getTime(),
        };
      }

      const cameraWidgetProps = {
        appConfig,
        sessionToken: token,
        resourceId: widget.resourceMapping.resourceId,
        subsystemId: "ADMS",
        driverApiUrl: `${SERVER_URL}${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping/${widget.resourceMapping.resourceMappingId}/get-livestream`,
        widgetWrapperProps,
        connectionEstablished: (id, cameraStatus) => {
          connectionEstablishedCallback(id, cameraStatus);
        },
        aspectRatioEnabled: false,
        errorHandler: ({ code, description }: ErrorData) => {
          alertSubject.next({
            title:
              code === 0
                ? translate(getErrorLabelCameraWidget(code), {
                    resourceName: widget.resourceMapping.name,
                  })
                : description,
          });
        },
        errorDictionary: ({ code }: ErrorData) => {
          return translate(getErrorLabelCameraWidget(code), {
            resourceName: widget.resourceMapping.name,
          });
        },
        ...videoStartAt,
        translate,
      };

      return <CameraWidgetComponent {...cameraWidgetProps} />;
    }

    return (
      <WidgetWrapperComponent {...widgetWrapperProps}>
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "lightgray",
          }}
        />
      </WidgetWrapperComponent>
    );
  };

  const buildGridItems = (widgetList) => {
    let tempArray = [...widgetList];
    tempArray = tempArray.sort((w1, w2) => {
      return parseInt(w2.id) - parseInt(w1.id);
    });
    return tempArray?.map((widget: Widget) => {
      let uniqueKey = `widget-grid-item-${widget.id}-${widget.type}`;
      if (WidgetType.RECORDED_VIDEO === widget.type) {
        uniqueKey = `${uniqueKey}_${widget.transientId}`;
      }
      return (
        <Grid
          item
          xs="auto"
          key={uniqueKey}
          data-cr="widget-item"
          id={uniqueKey}
        >
          <ErrorBoundary fallback={<ErrorPage />}>
            <React.Suspense fallback={<CircularProgress />}>
              <div
                className="reactGridItem"
                id={`react-grid-item-${widget.id}-${widget.type}${
                  widget.transientId ? `_${widget.transientId}` : ""
                }`}
              >
                {getWrapper(widget)}
              </div>
            </React.Suspense>
          </ErrorBoundary>
        </Grid>
      );
    });
  };

  return (
    <>
      <SizeMe>
        {() => {
          return (
            <div
              ref={widgetZoneRef}
              className="widget-zone compass-rounded-corner"
              data-cr="widget-zone"
            >
              {alertIsOpen ? (
                <Alert
                  variant="filled"
                  severity="info"
                  sx={{ position: "absolute", zIndex: "1", top: "10px" }}
                  data-cr="widget-zone-duplicated-alert"
                >
                  {alertMessage}
                </Alert>
              ) : (
                <></>
              )}
              {widgets && widgets?.length > 0 ? (
                <div
                  className="widget-zone-layout"
                  data-cr="widget-zone-layout"
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    data-cr="widget-zone-grid"
                  >
                    {buildGridItems(widgets)}
                  </Grid>
                </div>
              ) : (
                <div className="widget-zone-empty" data-cr="widget-zone-empty">
                  {translate("widgetZone.noWidgetAdded")}
                </div>
              )}
            </div>
          );
        }}
      </SizeMe>
    </>
  );
};

WidgetZone.defaultProps = {
  defaultWidgets: [],
  camerasToOpen: undefined,
};

export default WidgetZone;
