import React, { useEffect, useState } from "react";
import "./footer.module.css";
import SendIcon from "@mui/icons-material/SendRounded";
import AttachFileIcon from "@mui/icons-material/AttachFileRounded";
import { getMinimalIncidentId, Incident, useI18n } from "compass-commons";
import { Button, TextField } from "dms-lib";
import OperatorGuideService from "../../../services/OperatorGuideService";
import { OGExecutionComment } from "../../../model/OG/OGExecutionComment";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import ClassifyIncident, {
  ClassificationResult,
} from "./classifyIncident/ClassifyIncident";
import IncidentClearRequestError from "../../../errors/IncidentClearRequestError";
import { ExecutionTaskType } from "../../../model/OG/ExecutionTaskType";
import { COMMENTS_MAXIMUM_CHARACTERS } from "../../../utils/constants";

interface OperatorGuideFooterProps {
  incident: Incident;
  executionId: string;
  hasActiveTask?: boolean;
}

const OperatorGuideFooter = (props: OperatorGuideFooterProps): JSX.Element => {
  const { t: translate } = useI18n();

  // TODO - eventually implemented associated features
  const showNotImplementedFeatures = false;

  const { incident, executionId, hasActiveTask } = props;
  const { stateService } = useGlobalContext();
  const {
    currentOgExecution,
    currentExecutionId,
    currentWidgets,
    clearIncidentAlert,
  } = stateService;
  const [textChanging, setTextChanging] = useState(false);
  const [comment, setComment] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [clearIncidentButtonDisabled, setClearIncidentButtonDisabled] =
    useState(hasActiveTask);
  const [inputDisabled, setInputDisabled] = useState(false);

  useEffect(() => {
    setClearIncidentButtonDisabled(hasActiveTask);
  }, [hasActiveTask]);

  /** Static texts block */
  const INCIDENT_CLEAR_ALL_BUTTON_TEXT = "Clear All";
  const INCIDENT_SEND_TO_BUTTON_TEXT = "Send to";
  const INCIDENT_CHANGE_TYPE_BUTTON_TEXT = "Change Type";
  const INCIDENT_CHANGE_PRIORITY_BUTTON_TEXT = "Change Priority";
  const INCIDENT_POSTPONE_BUTTON_TEXT = "Postpone";
  /** Static text block ends */

  const onChangeHandler = (event) => {
    if (event.target.value?.trim().length > 0) {
      setTextChanging(true);
    } else {
      setTextChanging(false);
    }

    setComment(
      event.target.value.substring(0, COMMENTS_MAXIMUM_CHARACTERS - 1)
    );
  };

  const commentAddedCallbackHandler = (newComment: OGExecutionComment) => {
    if (newComment) {
      const execution = currentOgExecution.value;
      const stepsList = execution?.registryResponse?.stepsList;
      if (stepsList) {
        stepsList.push({ ...newComment, type: ExecutionTaskType.Comment });
        execution.registryResponse.stepsList = stepsList;
        currentOgExecution.next(null); // when we do any change on same object, BehaviourSubject does not update its state.
        currentOgExecution.next(execution);
      }
    }
  };

  const addCommentHandler = () => {
    if (comment.length > 0 && comment.length <= 256) {
      setInputDisabled(true);
      OperatorGuideService.addCommentToExecution(executionId, comment)
        .then((res) => {
          commentAddedCallbackHandler(res);
          setComment("");
          setTextChanging(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setInputDisabled(false);
        });
    }
  };

  const keyPressHandler = (event) => {
    if (event.charCode === 13 || event.key === "Enter") {
      event.preventDefault();
      addCommentHandler();
    }
  };

  const clearIncident = (clearComment?: string) => {
    const clearCall = () => {
      OperatorGuideService.clearIncident(incident.id, executionId)
        .then(() => {
          clearIncidentAlert.next({
            icon: incident.icon,
            message: translate("incident.incidentIdentification", {
              incidentId: getMinimalIncidentId(incident.id),
            }),
            priority: incident.priority,
          });
          currentOgExecution.next(null);
          currentExecutionId.next("-1"); // We cleared and we will pick new one
          currentWidgets.next([]);
        })
        .catch((error) => {
          throw new IncidentClearRequestError(error);
        });
    };

    if (clearComment) {
      OperatorGuideService.addCommentToExecution(executionId, clearComment)
        .then(clearCall)
        .catch((error) => {
          console.log(error);
        });
    } else {
      clearCall();
    }
  };

  const classifyIncidentCallbackHandler = (val: ClassificationResult) => {
    if (val?.comment) {
      clearIncident(val.comment);
    }
    setOpenDialog(false);
  };

  return (
    <>
      {openDialog && (
        <ClassifyIncident
          openDialog={openDialog}
          isOGCompleted={!hasActiveTask}
          selectionCallback={classifyIncidentCallbackHandler}
          showNotImplementedFeatures={showNotImplementedFeatures}
        />
      )}
      <div className="operator-guide-footer">
        <div className="operator-guide-comment">
          <div className="comment-text">
            <TextField
              data-cr="comment-text-field"
              multiline
              maxRows={3}
              placeholder={translate("incident.commentInputPlaceholder")}
              size="small"
              onChange={onChangeHandler}
              fullWidth
              value={comment}
              onKeyDown={keyPressHandler}
              disabled={inputDisabled}
              iconEnd={
                <Button
                  dataCr="comment-text-button"
                  color="primary"
                  variant="contained"
                  disabled={!textChanging}
                  onClick={addCommentHandler}
                  size="small"
                  icon
                >
                  <SendIcon />
                </Button>
              }
            />
          </div>
          {showNotImplementedFeatures && (
            <div className="comment-buttons">
              <div className="comment-attach compass-box-shadow">
                <div className="comment-attach-wrapper">
                  <AttachFileIcon
                    color="disabled"
                    sx={{
                      fontSize: "1.8em",
                      color: "#fff",
                      transform: "rotate(45deg)",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showNotImplementedFeatures && (
          <div className="operator-guide-incident-actions">
            <div>
              <Button
                onClick={() => {
                  console.log("send to");
                }}
                color="primary"
                variant="contained"
              >
                <span>{INCIDENT_SEND_TO_BUTTON_TEXT}</span>
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  console.log("change type");
                }}
                color="primary"
                variant="contained"
              >
                <span>{INCIDENT_CHANGE_TYPE_BUTTON_TEXT}</span>
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  console.log("change priority");
                }}
                color="primary"
                variant="contained"
              >
                <span>{INCIDENT_CHANGE_PRIORITY_BUTTON_TEXT}</span>
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  console.log("postpone");
                }}
                color="primary"
                variant="contained"
              >
                <span>{INCIDENT_POSTPONE_BUTTON_TEXT}</span>
              </Button>
            </div>
          </div>
        )}

        <div className="operator-guide-incident-actions">
          {showNotImplementedFeatures && (
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  console.log("clear all");
                }}
                className={hasActiveTask ? "disabled" : ""}
              >
                <span>{INCIDENT_CLEAR_ALL_BUTTON_TEXT}</span>
              </Button>
            </div>
          )}
          <Button
            dataCr="clear-incident-button"
            color="primary"
            variant="contained"
            onClick={() => {
              if (showNotImplementedFeatures || hasActiveTask) {
                setOpenDialog(true);
              } else {
                clearIncident();
              }
            }}
            className={clearIncidentButtonDisabled ? "disabled" : ""}
            onMouseEnter={() => setClearIncidentButtonDisabled(false)}
            onMouseLeave={() => setClearIncidentButtonDisabled(hasActiveTask)}
          >
            <span>{translate("incident.clearBtn")}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

OperatorGuideFooter.defaultProps = {
  hasActiveTask: false,
};

export default OperatorGuideFooter;
