import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  InfoFieldDTO,
  InfoFieldType,
} from "../../../../../../../model/OG/InfoFieldDTO";

interface SingleInfoFieldProps {
  infoField: InfoFieldDTO;
  index: number;
}

function SingleInfoField(props: SingleInfoFieldProps): React.JSX.Element {
  const { infoField, index } = props;

  const [renderedLabel, setRenderedLabel] = useState("");

  const isFieldNotDisplayable = (field: InfoFieldDTO) => {
    // If the field value is empty or is a photo id, we don't want to show it
    return !field.value || field.value === "" || field.type === "PHOTO_ID";
  };

  useEffect(() => {
    if (isFieldNotDisplayable(infoField)) return;

    // We want to show the label of the field if it exists, otherwise we show the type
    // TODO : for these situations, we should have a translation for types
    let labelToDisplay = infoField.label;
    if (!labelToDisplay) {
      labelToDisplay = infoField.type.toLowerCase();
      labelToDisplay =
        labelToDisplay.charAt(0).toUpperCase() + labelToDisplay.slice(1);
    }

    // If the field is an email and has a label, we want to show it as "Email (label)" e.g., Email (Work)
    if (
      infoField.type === InfoFieldType.EMAIL &&
      infoField.label &&
      infoField.label !== ""
    ) {
      labelToDisplay = `Email (${infoField.label})`;
    }

    setRenderedLabel(labelToDisplay);
  }, [infoField]);

  return isFieldNotDisplayable(infoField) ? (
    <></>
  ) : (
    <Box
      key={index}
      data-cr="option-info-box"
      className="og-search-task-dropdown-info-box"
    >
      <Typography className="og-search-task-dropdown-single-field-label">
        {renderedLabel}
      </Typography>

      <Typography className="og-search-task-dropdown-single-field-value pivot-typography-body-2">
        {infoField.value}
      </Typography>
    </Box>
  );
}

// eslint-disable-next-line import/prefer-default-export
export { SingleInfoField };
