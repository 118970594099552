import { StateFunctionalityData } from "./StateFunctionalityData";

// eslint-disable-next-line no-shadow
export enum ResourceFunctionalityType {
  UNKNOWN = "UNKNOWN",
  HAS_STATE = "HAS_STATE",
  SEND_COMMAND = "SEND_COMMAND",
  EVENT_SOURCE = "EVENT_SOURCE",
  LIVE_VIDEO_STREAMING = "LIVE_VIDEO_STREAMING",
  DATABASE_SEARCH = "DATABASE_SEARCH",
}

export interface ResourceFunctionality {
  id: string;
  enabled: boolean;
  type: ResourceFunctionalityType;
  currentState: string;
  hasStateFunctionalityData: StateFunctionalityData;
  eventSourceFunctionalityData: any /** TODO implement a model when we know what kind of information this field has */;
  sendCommandFunctionalityData: any /** TODO implement a model when we know what kind of information this field has */;
  liveVideoStreamingFunctionalityData: any /** TODO implement a model when we know what kind of information this field has */;
}
