// General
import React, { useEffect, useRef, useState } from "react";
import {
  LocalizationNS,
  sessionStorageIdentifiers,
  useFeatureFlag,
  useGetOrganization,
  useGetToken,
  useI18n,
  withI18n,
} from "compass-commons";
import {
  getOrgIdFromToken,
  getValueFromTokenByKey,
  TokenKeys,
} from "compass-shared-services";
import { Alert, AppTitle, AuthWrapper, Button } from "dms-lib";
import {
  ExpandViewEvent,
  CloseAllCameras,
  ShellConfig,
  CollapseViewEvent,
} from "@msicie/public-types";
import { withOneTabEnforcer } from "./utils/SingleTab";
// Styles
import "./app.module.css";
import "./styles.scss";
// Components
import MapZone from "./components/mapZone/MapZone";
import WidgetZone from "./components/widgetZone/WidgetZone";
import OperatorGuide from "./components/operatorGuide/OperatorGuide";
import ClearIncidentAlert from "./components/commons/alert/ClearIncidentAlert";
// Contexts
import { GlobalProvider } from "./contexts/GlobalContext";
// Services
import { globalService } from "./services/GlobalService";
import UserManagerService from "./services/UserManagerService";
// Models
import { AssignedIncidentResponse } from "./model/incident/AssignedIncidentResponse";
// Utils
import AssignedIncidentDialog from "./components/assignedIncidentDialog";
import SessionExpiredDialog from "./components/sessionExpiredDialog/SessionExpiredDialog";
import { SocketAssignedIncidentDTO } from "./model/incident/SocketAssignedIncident";
import { IncidentState } from "./model/incident/IncidentState";
import { isAssigned } from "./helpers/incidentStateHelper";
import { useAdditionalActivations } from "./hooks/useAdditionalActivations";
import useSendHeartbeat from "./hooks/useSendHeartbeat";
import useRequestExecution from "./hooks/useRequestExecution";
import { ResourceMappingLightDTO } from "./model/incident/ResourceMappingLightDTO";
import {
  EDIT_ASSIGNMENT,
  SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG,
} from "./utils/constants";
import StoreProvider from "./store/StoreProvider";
import { OperationIncidentInfo } from "./model/notifications/OperationIncidentInfo";
import UnAssignedIncidentDialog from "./components/unAssignedIncidentDialog/UnAssignedIncidentDialog";
import HeartbeatState from "./model/heartbeat/HeartbeatState";

const {
  OPERATOR_ASSIGNED_INCIDENT_HUB,
  OPERATION_INCIDENT_INFO_HUB,
  MFE_HELP_LINK_KEYWORD,
  ENV,
  NAMESPACE,
} = appConfig;
const appName = `${ENV}/${NAMESPACE}/single-tab-incident-response`;
const CURRENT_USER_ID = "userId";

const App = <T extends { shellConfig?: ShellConfig }>({
  shellConfig = undefined,
}: T) => {
  const { t: translate } = useI18n();
  const token = (() => {
    const theToken = useGetToken();
    return isStandalone ? localStorage.getItem("token") : theToken;
  })();
  const currentToken = useRef(token);
  let organizationId = useGetOrganization(isDMS);
  const orgId = getOrgIdFromToken(token, isDMS);

  if (isStandalone && orgId && !organizationId) {
    organizationId = orgId;
  }

  const isSwitchingOrg = organizationId && organizationId !== orgId;
  const isLoggedInUser = Boolean(token);

  const [isAllowedUser, setIsAllowedUser] = useState<boolean>();
  const [isTabStateInitialized, setIsTabStateInitialized] = useState(false);

  const {
    currentExecutionId,
    clearIncidentAlert,
    incidentAdditionalActivations,
    alertSubject,
    incidentLocation,
    incidentNearestResources,
  } = globalService.stateService;
  const [assignedIncident, setAssignedIncident] =
    useState<AssignedIncidentResponse>(null);
  const [camerasToOpen, setCamerasToOpen] =
    useState<ResourceMappingLightDTO[]>(null);
  const [assignedIncidentDialogOpen, setAssignedIncidentDialogOpen] =
    useState(false);
  const [newAssignedIncident, setNewAssignedIncident] = useState(null);
  const [unAssignedIncidentDialogOpen, setUnAssignedIncidentDialogOpen] =
    useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const { isOffline, heartbeatState } = useSendHeartbeat(isLoggedInUser);
  const {
    requestExecution,
    currentAssignedIncident,
    isRequestExecutionInUse,
    currentAssignedIncidentRef,
  } = useRequestExecution();

  const [currentCustomerId, setCurrentCustomerId] = useState(null);
  const currentCustomerIdRef = useRef(currentCustomerId);

  // Activations
  const { handleAdditionalActivations, resetAdditionalActivations } =
    useAdditionalActivations(incidentAdditionalActivations);

  const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(
    appConfig,
    SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG
  );

  const { enabled: featureEditAssignment } = useFeatureFlag(
    appConfig,
    EDIT_ASSIGNMENT
  );

  const getVideoResources = (response: AssignedIncidentResponse) => {
    const videoResources: ResourceMappingLightDTO[] =
      response.videoResourceMappings?.map((rm: ResourceMappingLightDTO) => ({
        resourceId: rm.resourceId,
        resourceMappingId: rm.resourceMappingId,
        subsystemId: response.siteId,
        name: rm.name,
      }));

    return videoResources;
  };

  useEffect(() => {
    if (heartbeatState !== HeartbeatState.SUCCESS) {
      setIsSessionExpired(true);
    }
  }, [heartbeatState]);

  useEffect(() => {
    if (currentAssignedIncident) {
      currentExecutionId.next(
        currentAssignedIncident?.operatorGuideExecutionId
      );
      handleAdditionalActivations(currentAssignedIncident);
      setAssignedIncident(currentAssignedIncident);
      setCamerasToOpen(getVideoResources(currentAssignedIncident));
      incidentLocation.next(currentAssignedIncident?.location);
      incidentNearestResources.next(
        currentAssignedIncident?.videoResourceMappings
      );
    } else {
      setAssignedIncident(null);
    }
  }, [currentAssignedIncident]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const result = await UserManagerService.getCustomers(
          currentAssignedIncident.assignedToUserId
        );
        if (result) {
          const currentCustomer = result.customerDTOList.find(
            (customer) => customer.externalOrganizationId === organizationId
          );
          setCurrentCustomerId(currentCustomer?.id);
        }
      } catch (error) {
        console.error("Failed to fetch customers:", error);
      }
    };

    if (organizationId && currentAssignedIncident) {
      fetchCustomers();
    }
  }, [organizationId, currentAssignedIncident]);

  useEffect(() => {
    currentCustomerIdRef.current = currentCustomerId;
  }, [currentCustomerId]);

  const closeUnAssignedPopup = () => {
    if (unAssignedIncidentDialogOpen) {
      setUnAssignedIncidentDialogOpen(false);
    }
  };

  const handleCloseAssignedIncidentDialog = (incidentState: IncidentState) => {
    setAssignedIncidentDialogOpen(false);
    resetAdditionalActivations();
    if (!isDMS) {
      shellConfig.eventBus.trigger(new CloseAllCameras());
    }
    setAssignedIncident(null);
    // we may receive  operation info message before the assigned incident message
    // so we need to close the unassigned incident dialog if it is open
    closeUnAssignedPopup();
    if (isAssigned(incidentState)) requestExecution();
  };

  const handleCloseUnAssignedIncident = () => {
    setUnAssignedIncidentDialogOpen(false);
    resetAdditionalActivations();
    if (!isDMS) {
      shellConfig.eventBus.trigger(new CloseAllCameras());
      shellConfig.eventBus.trigger(new CollapseViewEvent());
    }
    setAssignedIncident(null);
    requestExecution();
  };

  const handleRefreshSessionExpiredDialog = () => {
    setIsSessionExpired(false);
    window.location.reload();
  };

  const processNewIncidents = (
    validationStateCondition: boolean,
    newAssignedIncidentDTO: SocketAssignedIncidentDTO
  ) => {
    // on SOC it's possible to not have a current assigned incident (assign polling disabled)
    const assignStateCondition =
      (!isDMS || currentAssignedIncidentRef !== null) &&
      validationStateCondition;

    if (assignStateCondition) {
      setNewAssignedIncident(newAssignedIncidentDTO);
      if (!isDMS) {
        shellConfig.eventBus.trigger(new ExpandViewEvent());
      }
      if (
        newAssignedIncidentDTO.assignedByUserId !==
        sessionStorage.getItem(CURRENT_USER_ID)
      ) {
        setAssignedIncidentDialogOpen(true);
        // we may receive  operation info message before the assigned incident message
        // so we need to close the unassigned incident dialog if it is open
        closeUnAssignedPopup();
      } else if (!isDMS) {
        resetAdditionalActivations();
        requestExecution();
      }
    }
  };

  const handleAssignedIncidents = (
    newAssignedIncidentDTO: SocketAssignedIncidentDTO
  ) => {
    const stateCondition =
      currentAssignedIncidentRef.current?.id !== newAssignedIncidentDTO?.id;
    processNewIncidents(stateCondition, newAssignedIncidentDTO);
  };

  const handleClearedIncidents = (
    newAssignedIncidentDTO: SocketAssignedIncidentDTO
  ) => {
    const stateCondition =
      currentAssignedIncidentRef.current?.id === newAssignedIncidentDTO.id;
    processNewIncidents(stateCondition, newAssignedIncidentDTO);
  };

  const incidentStateToAction: {
    [key in IncidentState]: (
      newAssignedIncidentDTO: SocketAssignedIncidentDTO
    ) => void;
  } = {
    [IncidentState.ACTIVATION_COUNT_UPDATED]: (
      newAssignedIncidentDTO: SocketAssignedIncidentDTO
    ) => handleAdditionalActivations(newAssignedIncidentDTO),
    [IncidentState.ASSIGNED]: (
      newAssignedIncidentDTO: SocketAssignedIncidentDTO
    ) => handleAssignedIncidents(newAssignedIncidentDTO),
    [IncidentState.CLEARED]: (
      newAssignedIncidentDTO: SocketAssignedIncidentDTO
    ) => handleClearedIncidents(newAssignedIncidentDTO),
  };

  const processHubMessage = (msg) => {
    const newAssignedIncidentDTO = JSON.parse(msg) as SocketAssignedIncidentDTO;
    if (
      isDMS &&
      newAssignedIncidentDTO.customerId !== currentCustomerIdRef.current
    )
      return;

    incidentStateToAction[newAssignedIncidentDTO.incidentState]?.(
      newAssignedIncidentDTO
    );
  };

  const processIncidentInfoMessage = (msg) => {
    const operationIncidentInfo = JSON.parse(msg) as OperationIncidentInfo;
    if (
      featureEditAssignment &&
      operationIncidentInfo &&
      operationIncidentInfo.incidentId ===
        currentAssignedIncidentRef.current?.incidentId &&
      currentAssignedIncidentRef.current?.assignedToUserId &&
      (currentAssignedIncidentRef.current?.assignedToUserId !==
        operationIncidentInfo.assignedToUserId ||
        operationIncidentInfo.assignedToUserId === null)
    ) {
      if (!isDMS) {
        shellConfig.eventBus.trigger(new ExpandViewEvent());
      }
      if (
        isDMS ||
        operationIncidentInfo.lastUpdatedUser !==
          sessionStorage.getItem(CURRENT_USER_ID)
      ) {
        setUnAssignedIncidentDialogOpen(true);
      } else {
        resetAdditionalActivations();
        shellConfig.eventBus.trigger(new CloseAllCameras());
        shellConfig.eventBus.trigger(new CollapseViewEvent());
        setAssignedIncident(null);
      }
    }
  };

  const connectToIncidentsPubSub = () => {
    globalService.listenerService.listen(
      OPERATOR_ASSIGNED_INCIDENT_HUB,
      processHubMessage,
      ""
    );

    globalService.listenerService.listen(
      OPERATION_INCIDENT_INFO_HUB,
      processIncidentInfoMessage,
      ""
    );
  };

  const disconnectFromIncidentsPubSub = () => {
    globalService.listenerService.stopListening(OPERATOR_ASSIGNED_INCIDENT_HUB);
  };

  useEffect(() => {
    if (isOffline) {
      disconnectFromIncidentsPubSub();
    }
  }, [isOffline]);

  const fetchAllowedUser = () => {
    UserManagerService.isUserAllowed().then((r) => {
      setIsAllowedUser(r);
    });
  };

  const fetchUserId = () => {
    let emailTokenKey = TokenKeys.Email;
    if (!isDMS) {
      emailTokenKey = "uid" as TokenKeys;
    }

    const userEmail = getValueFromTokenByKey(token, emailTokenKey);
    if (userEmail && organizationId) {
      UserManagerService.getUserByEmailAndOrganizationId(
        userEmail,
        organizationId
      ).then((r) => {
        sessionStorage.setItem(CURRENT_USER_ID, r.userId);
      });
    }
  };

  useEffect(() => {
    if (!isLoggedInUser) return;

    fetchAllowedUser();
  }, [isLoggedInUser]);

  useEffect(() => {
    if (isLoggedInUser && organizationId) {
      fetchUserId();
    }
  }, [isLoggedInUser, organizationId]);

  useEffect(() => {
    const currentExecutionIdSubs = currentExecutionId.subscribe(
      (val: string) => {
        if (val && val === "-1") {
          setAssignedIncident(null);
          resetAdditionalActivations();
        }
      }
    );

    return function cleanup() {
      currentExecutionIdSubs.unsubscribe();
    };
  }, [currentExecutionId]);

  const OPERATION_RUNNING_UPDATE_PERIOD = 1000;
  const OPERATION_RUNNING_CHECK_PERIOD = 3 * OPERATION_RUNNING_UPDATE_PERIOD;

  const onResumeTab = () => {
    setIsSessionExpired(true);
  };

  useEffect(() => {
    const mainContextHelpKeyword = sessionStorage.getItem(
      sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD
    );

    if (mainContextHelpKeyword !== MFE_HELP_LINK_KEYWORD) {
      sessionStorage.setItem(
        sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD,
        MFE_HELP_LINK_KEYWORD
      );
    }
  }, []);

  useEffect(() => {
    window.document.addEventListener("resume", onResumeTab); // An event fired when the page has been resumed after being frozen by the operating system.
    return () => {
      window.document.removeEventListener("resume", onResumeTab);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsTabStateInitialized(true);
    }, OPERATION_RUNNING_CHECK_PERIOD);
  });

  const isStandaloneMode = () => {
    return isStandalone != null && isStandalone;
  };

  useEffect(() => {
    /* tslint:disable:no-empty */
    if (isAllowedUser === undefined) return;
    if ((!isLoggedInUser || !isAllowedUser) && !isStandaloneMode()) return;
    if (!assignedIncident && !isRequestExecutionInUse) {
      requestExecution();
    }
  }, [assignedIncident, isAllowedUser, isLoggedInUser]);

  useEffect(() => {
    setIsAllowedUser(undefined);
    setAssignedIncidentDialogOpen(false);
    setAssignedIncident(null);
    resetAdditionalActivations();
    fetchAllowedUser();
  }, [orgId]);

  const notReadyYet = () => {
    return <div className="operation-root noincidents-main-root" />;
  };

  const incidentAvailable = () => {
    return (
      <>
        <div
          id="operation-root"
          className={`operation-root compass-rounded-corner compass-fade-in-smooth ${
            isDMS && "operation-root-padding"
          }`}
          data-cr="operation-root"
        >
          {isDMS && (
            <MapZone
              mapSiteId={assignedIncident?.siteId}
              resourceMappingId={assignedIncident?.resourceMappingId}
              assignedIncident={assignedIncident}
            />
          )}
          {isDMS && <WidgetZone camerasToOpen={camerasToOpen} />}
          <OperatorGuide
            assignedIncidentResponse={assignedIncident}
            shellConfig={shellConfig}
          />
        </div>
        <div id="modal-root" />
      </>
    );
  };

  const noIncidents = () => {
    return (
      <div className="operation-root noincidents-main-root">
        <div className="results-view-no-incidents">
          <div className="no-results-title">
            {translate("incident.noIncidents")}
          </div>
        </div>
      </div>
    );
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  const refreshAction = (
    <Button size="small" color="primary" variant="text" onClick={handleRefresh}>
      Refresh
    </Button>
  );
  useEffect(() => {
    const handleReject = () => {
      if (!showErrorMessagesFeatureFlag) return;
      alertSubject.next({
        title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
        description: translate("genericErrorSubtitle", {
          ns: LocalizationNS.SHARED,
        }),
        action: refreshAction,
      });
    };
    window.addEventListener("unhandledrejection", handleReject);
    return () => {
      window.removeEventListener("unhandledrejection", handleReject);
    };
  }, [showErrorMessagesFeatureFlag]);

  const getContent = () => {
    if (!isTabStateInitialized || isSwitchingOrg) {
      return notReadyYet();
    }
    if (assignedIncident) {
      return incidentAvailable();
    }
    return noIncidents();
  };

  useEffect(() => {
    currentToken.current = token;
  }, [token]);

  useEffect(() => {
    if (isAllowedUser) {
      connectToIncidentsPubSub();
    }
  }, [isAllowedUser]);

  return (
    (isLoggedInUser || isStandaloneMode()) && (
      <React.StrictMode>
        <StoreProvider>
          <GlobalProvider value={globalService}>
            {isDMS && (
              <AppTitle translate={translate} localizationNS={LocalizationNS} />
            )}
            <AuthWrapper
              isAuthorized={isAllowedUser || isStandaloneMode()}
              isLoading={
                isStandaloneMode() ? false : isAllowedUser === undefined
              }
              unauthorizedTitle={translate("unauthorized", {
                ns: LocalizationNS.SHARED,
              })}
              unauthorizedDescription={translate("unauthorizedContact", {
                ns: LocalizationNS.SHARED,
              })}
            >
              {getContent()}
              <Alert
                alertNotificationSubject={alertSubject}
                autoHideDuration={4000}
              />
              <ClearIncidentAlert
                clearIncidentAlertSubject={clearIncidentAlert}
              />
              <AssignedIncidentDialog
                isOpen={assignedIncidentDialogOpen}
                handleClose={handleCloseAssignedIncidentDialog}
                incident={newAssignedIncident}
              />
              <UnAssignedIncidentDialog
                isOpen={unAssignedIncidentDialogOpen}
                handleClose={handleCloseUnAssignedIncident}
              />
              <SessionExpiredDialog
                isOpen={isSessionExpired}
                handleRefresh={handleRefreshSessionExpiredDialog}
                state={heartbeatState}
              />
            </AuthWrapper>
          </GlobalProvider>
        </StoreProvider>
      </React.StrictMode>
    )
  );
};

const otherTabOpen = () => {
  return (
    <div
      id="operation-root"
      className="operation-root noincidents-main-root"
      data-cr="operation-root"
    >
      <div className="results-view-no-incidents">
        <div className="no-results-title">
          {useI18n().t("incident.alreadyOpened")}
        </div>
      </div>
    </div>
  );
};

export default withI18n(
  withOneTabEnforcer({
    appName,
    localStorageResetInterval: 1000, // 1 seconds
    localStorageTimeout: 5 * 1000, // 5 seconds
    OnlyOneTabComponent: otherTabOpen,
  })(App)
);
