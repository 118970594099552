// General
import {
  AllowedModules,
  CompassAuth,
  HttpClient,
} from "compass-shared-services";
import { getUrl } from "compass-commons";
import { SSOUserDTO } from "../model/SSOUserDTO";

const USER_MANAGER_URL = appConfig.USER_MANAGER_SERVICE_PATH;

const URL_PATHS = {
  GET_USER_USERGROUPS: `${USER_MANAGER_URL}/manager/user-groups/logged-user`,
  GET_USER_CUSTOMERS: `${USER_MANAGER_URL}/manager/users/(userId)/customers`,
};

const compassAuth = CompassAuth(appConfig.SERVER_URL, appConfig.NAMESPACE);

export type CustomerDTO = {
  id: string;
  name: string;
  externalOrganizationId: string;
};

export type CustomersDTO = {
  currentCustomerId: string;
  customerDTOList: CustomerDTO[];
};

const httpClient = new HttpClient(appConfig);

class UserManagerService {
  static async isUserAllowed(): Promise<boolean> {
    try {
      return await compassAuth.isUserAllowed(
        URL_PATHS.GET_USER_USERGROUPS,
        AllowedModules.OPERATION
      );
    } catch (error) {
      return false;
    }
  }

  static async getCustomers(userId: string): Promise<CustomersDTO> {
    const uri = {
      userId,
    };
    const urlPath = getUrl(URL_PATHS.GET_USER_CUSTOMERS, uri);

    return httpClient
      .get<CustomersDTO>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(JSON.stringify(error));
      });
  }

  static async getUserByEmailAndOrganizationId(
    email: string,
    organizationId: string
  ): Promise<SSOUserDTO> {
    const urlPath = `${USER_MANAGER_URL}/manager/user-by-email?email=${encodeURIComponent(
      email
    )}&externalOrganizationId=${encodeURIComponent(organizationId)}`;

    return httpClient
      .get<any>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(JSON.stringify(error));
      });
  }
}

export default UserManagerService;
