import React, { useEffect, useState } from "react";
import { Avatar, Box, InputAdornment } from "@mui/material";
import { HelpCenterRounded, ImageRounded } from "@mui/icons-material";
import { ImageLoadState } from "../../../../../../model/databaseSearch/internal/SearchImageBase";
import DatabaseSearchResult from "../../../../../../model/databaseSearch/internal/DatabaseSearchResult";
import OptionType from "../../../../../../model/databaseSearch/internal/OptionType";

interface SearchBarStartAdornmentProps {
  selectedOption: DatabaseSearchResult;
}

function SearchBarStartAdornment(
  props: SearchBarStartAdornmentProps
): React.JSX.Element {
  const { selectedOption } = props;

  const [startAdornmentValue, setStartAdornmentValue] = useState(null);

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.type === OptionType.NOT_FOUND) {
        setStartAdornmentValue(
          <InputAdornment position="start">
            <Avatar
              data-cr="minimized-selection-avatar-notfound"
              className="og-search-task-minimized-selection-avatar"
            >
              <HelpCenterRounded className="og-search-task-minimized-icon" />
            </Avatar>
          </InputAdornment>
        );
      }

      if (selectedOption.type === OptionType.FOUND) {
        const adornmentValue =
          selectedOption.images[0] &&
          selectedOption.images[0].state === ImageLoadState.LOADED ? (
            // Display fetched image
            <Box className="og-search-task-minimized-selection">
              <InputAdornment position="start">
                <Avatar
                  data-cr="minimized-selection-avatar-image-loaded"
                  className="og-search-task-minimized-selection-avatar avatar-border"
                  src={`data:image/${selectedOption.images[0].fileType};base64,${selectedOption.images[0].imageData}`}
                />
              </InputAdornment>
            </Box>
          ) : (
            <InputAdornment position="start">
              <Avatar
                data-cr="minimized-selection-avatar-image-not-loaded"
                className="og-search-task-minimized-selection-avatar"
              >
                <ImageRounded className="og-search-task-minimized-icon" />
              </Avatar>
            </InputAdornment>
          );

        setStartAdornmentValue(adornmentValue);
      }
    } else {
      setStartAdornmentValue(null);
    }
  }, [selectedOption]);

  return (
    <Box className="og-search-task-minimized-selection">
      {startAdornmentValue}
    </Box>
  );
}

export default SearchBarStartAdornment;
