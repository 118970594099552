// General
import { useEffect, useRef, useState } from "react";
// Services
import { useI18n } from "compass-commons";
import OperatorGuideService from "../services/OperatorGuideService";
// Models
import { OperatorUserHeartbeatResponseDTO } from "../model/heartbeat/OperatorUserHeartbeatResponseDTO";
// Utils
import { secondsToMilis } from "../utils/Utils";
import { globalService } from "../services/GlobalService";
import HeartbeatState from "../model/heartbeat/HeartbeatState";
import getSessionDialogText from "../helpers/sessionDialogHelper";

const NETWORK_CHECK_INTERVAL = 3000;
export default function useSendHeartbeat(isLoggedIn: boolean) {
  const { t: translate } = useI18n();
  const [isOffline, setIsOffline] = useState(false);
  const [heartbeatState, setHeartbeatState] = useState<HeartbeatState>(
    HeartbeatState.SUCCESS
  );
  const heartbeatTimeout = useRef<NodeJS.Timeout>();

  const { alertSubject } = globalService.stateService;

  useEffect(() => {
    let isMounted = true;
    let lastSessionId: string;
    let failedHeartbeatCount = 0;
    const operatorUserSendHeartbeat = () => {
      OperatorGuideService.operatorUserSendHeartBeat()
        .then((heartbeatResponse: OperatorUserHeartbeatResponseDTO) => {
          if (!isMounted) {
            return;
          }
          // Handling the incident
          // Send heartbeat at each 10 seconds
          // Heartbeat fails -> Operator is offline
          // Send heartbeat at each 3 seconds
          // If SendHeartbeat with success -> show session expired if new session
          if (lastSessionId && heartbeatResponse.sessionId !== lastSessionId) {
            setHeartbeatState(HeartbeatState.SESSION_EXPIRED);
            setIsOffline(true);
          }
          lastSessionId = heartbeatResponse.sessionId;
          failedHeartbeatCount = 0;
          heartbeatTimeout.current = setTimeout(
            operatorUserSendHeartbeat,
            secondsToMilis(heartbeatResponse.nextBeatSeconds)
          );
        })
        .catch((error) => {
          if (!isMounted) {
            return;
          }
          heartbeatTimeout.current = setTimeout(
            operatorUserSendHeartbeat,
            NETWORK_CHECK_INTERVAL
          );

          // Handle heartbeat errors
          if (error.cause === "ERR_NETWORK" || error.cause === "ECONNABORTED") {
            failedHeartbeatCount += 1;
            const state =
              error.cause === "ECONNABORTED"
                ? HeartbeatState.TIMEOUT
                : HeartbeatState.NETWORK_ERROR;

            if (failedHeartbeatCount > 2) {
              setHeartbeatState(state);
              setIsOffline(true);
            } else {
              alertSubject.next({
                severity: "warning",
                title: translate(getSessionDialogText(state)),
              });
            }
          } else {
            // Generic error
            throw error;
          }
        });
    };
    if (isLoggedIn) {
      operatorUserSendHeartbeat();
    }

    return () => {
      clearTimeout(heartbeatTimeout.current);
      isMounted = false;
    };
  }, [isLoggedIn]);

  return { isOffline, heartbeatState };
}
