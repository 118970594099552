import React from "react";
import { Avatar } from "@mui/material";
import { HelpCenterRounded } from "@mui/icons-material";
import { OptionType } from "../../../../../../../model/databaseSearch/internal/OptionType";
import { SingleImage } from "./SingleImage";
import { SearchImageBase } from "../../../../../../../model/databaseSearch/internal/SearchImageBase";

interface OptionImagesProps {
  optionId: string;
  optionType: OptionType;
  images: SearchImageBase[];
  displayingSelectedOption: boolean;
  subsystemId: string;
  onImageStateChange?: (
    image: SearchImageBase,
    optionId: string,
    imageId: string
  ) => void;
}

const OptionImages = (props: OptionImagesProps): React.JSX.Element => {
  const {
    optionId,
    optionType,
    images,
    displayingSelectedOption,
    subsystemId,
    onImageStateChange,
  } = props;

  return (
    <>
      {optionType === OptionType.FOUND && (
        <div className="og-search-task-dropdown-option-images">
          {images.map((image) => (
            <SingleImage
              key={image.imageId}
              image={image}
              subsystemId={subsystemId}
              displayingSelectedOption={displayingSelectedOption}
              onImageStateChange={onImageStateChange}
              optionId={optionId}
            />
          ))}
        </div>
      )}
      {optionType === OptionType.NOT_FOUND && (
        // Display Not Found icon
        <div className="og-search-task-dropdown-option-images">
          <Avatar
            data-cr="avatar-image-not-found"
            className="og-search-task-dropdown-avatar"
          >
            <HelpCenterRounded className="og-search-task-dropdown-option-avatar-icon" />
          </Avatar>
        </div>
      )}
      {optionType === OptionType.MAX_RESULTS && null}
    </>
  );
};

OptionImages.defaultProps = {
  onImageStateChange: null,
};

// eslint-disable-next-line import/prefer-default-export
export { OptionImages };
