import React, { PropsWithChildren } from "react";
import "./UnAssignedIncidentDialog.module.css";
import { Button, Dialog } from "dms-lib";
import { LocalizationNS, useI18n } from "compass-commons";

interface UnAssignedIncidentDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

const UnAssignedIncidentDialog = ({
  isOpen,
  handleClose,
}: PropsWithChildren<UnAssignedIncidentDialogProps>): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <Dialog
      open={isOpen}
      className="unassigned-incident-dialog-main"
      dialogContent={
        <div
          className="unassigned-incident-dialog"
          data-cr="unassigned-incident-dialog"
        >
          <div className="unassigned-incident-dialog-content">
            <p className="dms-type-hint">
              {translate("incident.unAssignedFromUser")}
            </p>
            <Button
              id="confirm-unassignment-button"
              onClick={() => handleClose()}
              color="primary"
              variant="contained"
              className="confirm-unassignment-button"
            >
              {translate("ok", { ns: LocalizationNS.SHARED })}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default UnAssignedIncidentDialog;
