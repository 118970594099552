export interface SearchImageBase {
  imageId: string;
  imageData?: Buffer;
  fileType?: string;
  state: ImageLoadState;
}

// eslint-disable-next-line
export enum ImageLoadState {
  LOAD_NOT_STARTED = "LOAD_NOT_STARTED",
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR_LOADING = "ERROR_LOADING",
}
