import React from "react";
import "./sessionExpiredDialog.module.css";
import { useI18n } from "compass-commons";

import InfoIcon from "@mui/icons-material/InfoRounded";
import { Button, Dialog } from "dms-lib";
import HeartbeatState from "../../model/heartbeat/HeartbeatState";
import getSessionDialogText from "../../helpers/sessionDialogHelper";

interface SessionExpiredDialogProps {
  isOpen: boolean;
  handleRefresh: () => void;
  state: HeartbeatState;
}

const SessionExpiredDialog = ({
  isOpen,
  handleRefresh,
  state,
}: SessionExpiredDialogProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <Dialog
      open={isOpen}
      size="xs"
      dialogContent={
        <div
          className="session-expired-dialog"
          data-cr="session-expired-dialog"
        >
          <div className="session-expired-dialog-content">
            <div className="session-expired-dialog-body">
              <InfoIcon fontSize="large" />
              <p
                className="session-expired-dialog-title"
                data-cr="session-expired-text"
              >
                {translate(getSessionDialogText(state))}
              </p>
            </div>
            <Button
              onClick={handleRefresh}
              color="primary"
              variant="contained"
              className="confirm-refresh-button"
            >
              {translate("incident.refreshDialog.refresh")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default SessionExpiredDialog;
