// Generic
import React, { useState, useEffect } from "react";
import { useI18n } from "compass-commons";
// Styles
import "./additionalActivationTask.scss";
import RepeatIcon from "@mui/icons-material/RepeatRounded";
// Components
import ResourceInteractionBlock from "../taskBlocks/ResourceInteractionBlock";
// Models
import { OGExecutionAdditionalActivationInfo } from "../../../../../model/OG/OGExecutionAdditionalActivationsInfo";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";
import { ResourceCommandAdditionalInfo } from "../../../../../model/resource/ResourceCommand";
import ResourceMappingService from "../../../../../services/ResourceMappingService";
import { ResourceMapping } from "../../../../../model/resource/ResourceMapping";

interface AdditionalActivationTaskProps {
  executionId: string;
  task: OGExecutionAdditionalActivationInfo;
  taskState: TaskStepState;
  resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo;
}

const AdditionalActivationTask = ({
  executionId,
  task,
  taskState,
  resourceCommandAdditionalInfo,
}: AdditionalActivationTaskProps): JSX.Element => {
  const { t: translate } = useI18n();

  const [{ loading, resourceMapping }, setResourceMappingWrapper] = useState<{
    loading: boolean;
    resourceMapping: ResourceMapping;
  }>({
    loading: false,
    resourceMapping: null,
  });

  /**
   * Function that obtains a resource mapping and stores it in stateful Map via useState hook for caching purposes
   * @param rmId - Resource Mapping ID
   */
  const getResourceMapping = async (rmId: string): Promise<void> => {
    try {
      setResourceMappingWrapper((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const rm = await ResourceMappingService.getResourceByIdCached(rmId);
      setResourceMappingWrapper({
        loading: false,
        resourceMapping: rm,
      });
    } catch (error) {
      setResourceMappingWrapper((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    getResourceMapping(task.resourceMappingId);
  }, []);

  return (
    <div className="operation-additional-activation__wrapper">
      <div className="operation-additional-activation__container">
        <RepeatIcon className="operation-additional-activation__icon" />
        <div className="operation-additional-activation__main">
          <div className="operation-additional-activation__title dms-type-label">
            {translate("incident.operatorGuideNewAdditionalActivations")}
          </div>
          <ResourceInteractionBlock
            executionId={executionId}
            id={task.id}
            taskState={taskState}
            resources={(resourceMapping && [resourceMapping]) || []}
            resourceCommandAdditionalInfo={resourceCommandAdditionalInfo}
            className={`operation-additional-activation__resource${
              loading
                ? " operation-additional-activation__resource--skeleton"
                : ""
            }`}
            isLoading={loading}
            disabled={false}
            videoStartTime={isDMS ? task.creationTimeStamp : undefined}
            ignoreResourceInteration
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalActivationTask;
