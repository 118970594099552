import HeartbeatState from "../model/heartbeat/HeartbeatState";

const getSessionDialogText = (state: HeartbeatState): string => {
  switch (state) {
    case HeartbeatState.SESSION_EXPIRED: {
      return "incident.refreshDialog.sessionExpired";
    }
    case HeartbeatState.NETWORK_ERROR: {
      return "incident.refreshDialog.networkError";
    }
    case HeartbeatState.TIMEOUT: {
      return "incident.refreshDialog.timeout";
    }
    default: {
      return "incident.refreshDialog.sessionExpired";
    }
  }
};

export default getSessionDialogText;
