import React from "react";
import { clearInterval, setInterval } from "worker-timers";

// This is based on react-one-tab-enforcer
// The library had an issue that did not stop the periodic task to update the session cookie
const DefaultOnlyOneTabComponent = () => (
  <div>Sorry! You can only have this application opened in one tab</div>
);

const isDuplicatedWindow = (
  localStorageTimeout,
  localStorageResetInterval,
  localStorageTabKey
) => {
  const ItemType = {
    Session: 1,
    Local: 2,
  };

  function setCookie(name, value) {
    const expires = "";
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }

  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function GetItem(itemtype) {
    let val;
    switch (itemtype) {
      case ItemType.Session:
        val = window.name;
        break;
      case ItemType.Local:
      default:
        val = decodeURIComponent(getCookie(localStorageTabKey));
        if (val === undefined) val = "";
        break;
    }
    return val;
  }

  function SetItem(itemtype, val) {
    switch (itemtype) {
      case ItemType.Session:
        window.name = val;
        break;
      case ItemType.Local:
      default:
        setCookie(localStorageTabKey, val);
        break;
    }
  }

  function createGUID() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  /**
   * Compare our tab identifier associated with this session (particular tab)
   * with that of one that is in window name Storage (the active one for this browser).
   * This browser tab is good if any of the following are true:
   * 1.  There is no cookie Storage Guid yet (first browser tab).
   * 2.  The window name Storage Guid matches the cookie Guid.  Same tab, refreshed.
   * 3.  The window name Storage timeout period has ended.
   *
   * If our current session is the correct active one, an interval will continue
   * to re-insert the window name Storage value with an updated timestamp.
   *
   * Another thing, that should be done (so you can open a tab within 15 seconds of closing it) would be to do the following (or hook onto an existing onunload method):
   */
  function isTabDuplicated() {
    const sessionGuid = GetItem(ItemType.Session) || createGUID();
    let intervalId = null;
    SetItem(ItemType.Session, sessionGuid);

    const cleanTabOpen = () => {
      if (intervalId) {
        clearInterval(intervalId);
        SetItem(ItemType.Local, "");
        intervalId = null;
      }
    };

    const val = GetItem(ItemType.Local);
    const tabObj = (val === "" ? null : JSON.parse(val)) || null;

    // If no or stale tab object, our session is the winner.  If the guid matches, ours is still the winner
    if (
      tabObj === null ||
      tabObj.timestamp < new Date().getTime() - localStorageTimeout ||
      tabObj.guid === sessionGuid
    ) {
      const setTabObj = () => {
        const newTabObj = {
          guid: sessionGuid,
          timestamp: new Date().getTime(),
        };
        SetItem(ItemType.Local, JSON.stringify(newTabObj));
      };

      setTabObj();
      intervalId = setInterval(setTabObj, localStorageResetInterval);

      [
        "pagehide",
        "beforeunload",
        "unload",
        "single-spa:before-app-change",
      ].forEach((event) => {
        window.addEventListener(event, cleanTabOpen);
      });
      return false;
    }
    // An active tab is already open that does not match our session guid.
    return true;
  }

  return isTabDuplicated();
};

// eslint-disable-next-line import/prefer-default-export
export function withOneTabEnforcer({
  OnlyOneTabComponent = DefaultOnlyOneTabComponent,
  localStorageTimeout = 15 * 1000, // 15,000 milliseconds = 15 seconds.
  localStorageResetInterval = 10 * 1000, // 10,000 milliseconds = 10 seconds.
  appName = "default-app-name", // has to be unique!
} = {}) {
  return (WrappedComponent) => {
    // ...and returns another component...
    return (props) => {
      if (
        isDuplicatedWindow(
          localStorageTimeout,
          localStorageResetInterval,
          appName
        )
      ) {
        return <OnlyOneTabComponent {...props} />;
      }
      return <WrappedComponent {...props} />;
    };
  };
}
