import { HttpClient } from "compass-shared-services";
import { getUrl, normalizeAPIObject } from "compass-commons";
import ImageDTO from "../model/databaseSearch/ImageDTO";

const { SUBSYSTEM_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  IMAGE: `${SUBSYSTEM_MANAGER_PATH}/subsystem-resources/subsystem/(subsystemId)/image/(imageId)`,
};

/**
 * Subsystem Manager Service where backend service is called
 * for OG operations
 */
export default class SubsystemManagerService {
  static async getImage(
    subsystemId: string,
    imageId: string
  ): Promise<ImageDTO> {
    const urlPath = `${getUrl(URL_PATHS.IMAGE, {
      subsystemId,
      imageId,
    })}`;

    return httpClient
      .get<ImageDTO>({
        url: urlPath,
      })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
